import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "amfe-flexible";
import Vant from "vant";
import "vant/lib/index.css";
import fastClick from "fastclick";
import { getCookie } from "@/utils/cookie";
fastClick.attach(document.body);
Vue.use(Vant);
Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  const c_time = getCookie("loginTime");
  document.title = "全国商用车行业指数平台";
  let time = 1000 * 60 * 60 * 48;
  let newD = new Date().getTime();
  var token = getCookie("token");
  if (c_time) {
    if (newD - c_time >= time) {
      token = "";
    }
  }
  if (to.fullPath !== "/Login") {
    if (!token) {
      next({ name: "Login" });
      return;
    }
  } else {
    if (token) {
      next({ name: "WhiteList" });
      return;
    }
  }
  next();
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
