import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: "/Login",
  },
  {
    path: "/Login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/WhiteList",
    name: "WhiteList",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/system/WhiteList.vue"),
      meta:{
        keepAlive: true 
      }
  },
  {
    path: "/details",
    name: "details",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/system/details.vue"),
  },
  {
    path: "/create",
    name: "create",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/system/create.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
