import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    LOADING: false,
    details: "",
    // api:'http://api.chinaucv.cn',
    api:'http://h5.chinaucv.cn/api',
    source:0,
  },
  mutations: {
    showLoading(state) {
      state.LOADING = true;
    },
    hideLoading(state) {
      state.LOADING = false;
    },
    details(state, data) {
      state.details = data;
    },
    set_source(state, data) {
      state.source = data;
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
});
