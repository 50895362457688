<template>
  <div id="app">
    <Loading v-show="LOADING"></Loading>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import Loading from "./components/loading.vue";
export default {
  computed: {
    ...mapState(["LOADING"]),
  },
  name: "App",
  components: { Loading },
};
</script>
<style lang="scss">
// 使用全局样式样式去掉
* {
  touch-action: pan-y;
}
</style>
